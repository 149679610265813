import React from 'react'

const HeartFilledWhite = (props) => (
  <svg data-name="Layer 1" viewBox="0 0 13 11" {...props}>
    <g data-name="Symbols">
      <g data-name="overview-/-xperience-tile">
        <g data-name="Group">
          <g data-name="icon-/-heart">
            <path data-name="Rectangle" fill="none" d="M0-1h13v13H0z" />
            <path
              data-name="Path"
              d="M6.5 2.787L7.505 1.78a2.838 2.838 0 114.014 4.014l-4.795 4.577a.325.325 0 01-.448 0L1.48 5.795A2.838 2.838 0 015.495 1.78z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default HeartFilledWhite
